@media (max-width: 1199px) {
  .header-top {
    font-size: 12px;
  }
}
@media (max-width: 991px) {
  .header-top .row > span {
    display: none;
  }
  .nav-toggler {
    display: block;
  }
  ul.navbar {
    display: none;
    width: 100% !important;
    border-radius: 12px;
    order: 1;
    box-shadow: 0 3px 16px #00000020;
    background: #fff;
    margin-top: 10px;
  }
  .navbar.shown {
    display: block;
  }
  .banner-form > * {
    flex: calc(50% - 8px);
  }
  section.testimonial .title,
  .testimonial .sub-title {
    text-align: center;
    width: 100%;
    display: block;
    max-width: 100%;
  }
  section.testimonial .title + p {
    margin: 10px auto;
    text-align: center;
  }
  section.testimonial .title + p + div {
    text-align: center;
    margin: 20px auto 50px !important;
    max-width: max-content;
  }
  footer .row {
    row-gap: 16px;
  }
  footer .col-lg-4 {
    text-align: center;
    margin-bottom: 14px;
  }
  footer .logo {
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
  .header-top {
    display: none;
  }
  .btn {
    height: 44px;
    line-height: 44px;
  }
  .form-select,
  .form-control,
  .form-select__control {
    height: 40px;
    font-size: 14px;
  }
  .actions-cover,
  .actions-cover > div {
    width: 100% !important;
  }
  h3 {
    font-size: 16px;
  }
  h3.text-primary {
    font-size: 22px;
  }
  .property-features-v2 > span svg {
    height: 20px;
    width: 20px;
    padding: 3px;
  }
  .btn {
    height: 40px;
    line-height: 40px;
  }
  .btn-dark {
    min-width: 40px;
    padding: 0;
  }
  .property-img {
    padding-top: 80%;
  }
  .logo {
    max-width: 80px;
  }
  header .btn {
    display: none;
  }
  ul.navbar {
    width: calc(100% - 20px) !important;
    margin: 10px auto 0;
  }
  .navbar a {
    width: calc(100% - 20px);
    padding: 8px 16px;
    color: #000;
    font-weight: 500;
    border-bottom: 1px solid #f3f3f3;
    margin: 0 10px;
  }
  .navbar li:last-child a {
    border: none;
  }
  .banner-content {
    text-align: center;
  }
  section.banner {
    height: auto;
    min-height: 100vh;
    padding-top: 150px;
  }
  .banner-form > * {
    flex: 100%;
  }
  .banner-form-top:before {
    display: none;
  }
  .banner-form {
    border-top-right-radius: 0;
  }
  section.categories h3 {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 5px;
  }
  section.categories .card > div > i {
    height: 50px;
    min-width: 50px;
    font-size: 24px;
    border-radius: 12px;
  }
  .tab-bar {
    margin-bottom: 12px;
  }
  section.places .col-md-6,
  section.places .col-12 {
    margin: 0 !important;
  }

  section.places .theme-img {
    margin: 6px 0;
  }
  section.testimonial .title + p + div {
    font-size: 14px;
  }
  footer ul li a i {
    position: static;
    margin-right: 10px;
  }
  .footer-bottom > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  section:not(.banner) {
    padding: 30px 0;
  }
  .tab-bar-cover {
    position: sticky;
    top: 77px;
    z-index: 2;
    background: rgb(255 247 229);
    padding: 8px 12px;
    margin: 0 -12px;
    align-items: center !important;
    border-bottom: 1px solid #ececec;
    border-top: 1px solid #ececec;
  }
  .banner-form-top {
    max-width: 100%;
  }
  .tab-bar button {
    padding: 3px 10px;
    border-radius: 35px;
    border: 1px solid transparent;
  }
  .tab-bar {
    margin-bottom: 0;
  }
  .tab-bar + button {
    padding-right: 0 !important;
  }
  .property-card {
    padding: 8px;
  }

  .property-card .theme-img {
    margin: -8px -8px 0;
  }

  .property-details {
    padding-top: 12px;
  }

  .property-badge {
    left: -15px;
    font-size: 8px;
    padding: 4px 10px;
    border-radius: 4px;
  }
  .property-details h5 {
    font-size: 14px;
  }
  .property-details h5 + p {
    font-size: 12px;
    margin-bottom: 0 !important;
  }
  .price {
    font-size: 16px;
  }
  .price .sub-text {
    font-size: 12px;
  }
  .property-features span svg {
    font-size: 14px;
  }
  .property-features span {
    font-size: 10px;
    padding: 2px 8px;
  }
  .property-features + .btn {
    margin-top: 8px !important;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
  }
  section:first-child:not(.banner) {
    padding-top: 90px;
  }
  .property-features-v2 > span {
    font-size: 12px;
    padding: 4px 10px 4px 4px;
    margin-right: 5px !important;
  }
  .g-4,
  .gy-4,
  .gx-4,
  .row {
    --bs-gutter-x: 0.8rem;
    --bs-gutter-y: 0.8rem;
  }
  .logo {
    max-width: 70px;
  }
  span.place-sub-title {
    font-size: 12px;
  }
  h3.place-title {
    font-size: 18px;
  }
  .place-title-cover {
    padding: 10px;
  }
  h4 {
    font-size: 18px;
  }
  .contact-box img {
    max-width: 100px;
  }
  footer {
    padding-top: 30px;
  }
  .footer-links h4 {
    display: flex;
    justify-content: space-between;
    background: #ffffff1a;
    padding: 10px;
    border-radius: 8px;
  }
  .title-cover {
    margin-bottom: 16px;
  }
  .footer-links {
    gap: 0 !important;
    margin-top: 24px;
  }
  .footer-links .col-md-3 {
    margin-top: 0px !important;
  }
  .footer-links ul {
    padding: 0 10px 10px;
  }
  button.wish-list-btn {
    height: 25px;
    width: 25px;
    line-height: 25px;
    font-size: 13px;
  }
  h2 {
    font-size: 18px;
  }

  .sub-title {
    font-size: 12px;
  }
}
