@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
:root {
  --primary-color: #ffb400;
}
img {
  max-width: 100%;
}
body {
  font-family: "Inter", sans-serif;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.radius-12 {
  border-radius: 12px !important;
}
.btn {
  border-radius: 12px;
  border: none;
  padding: 0px 16px;
  height: 50px;
  line-height: 50px;
  font-weight: 500;
}
.rounded-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #ececec;
}
.form-select__control {
  border-radius: 12px;
}
.form-select__control--is-focused {
  border-radius: 12px;
}
.btn-primary {
  background-color: var(--primary-color);
  transition: 0.3s;
}
.btn-light {
  background-color: #ececec;
}
.btn-primary:hover {
  background-color: #333;
}
.row > *:not([class*="col-"]) {
  width: auto;
}
.theme-img {
  position: relative;
  padding-top: 70%;
  overflow: hidden;
}
.theme-img img {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
a {
  text-decoration: none;
  display: inline-block;
}
button {
  background-color: transparent;
  border: none;
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
section:not(.banner) {
  padding: 70px 0;
}
section:first-child:not(.banner) {
  padding-top: 150px;
}
.title-cover {
  margin-bottom: 32px;
}
.title {
  max-width: 600px;
}

.sub-title {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--primary-color);
}
.card {
  border-color: #ececec;
  padding: 16px;
  /* box-shadow: 0 3px 20px #0000001a, 0 0 1px #0000001a; */
  border-radius: 20px;
  overflow: hidden;
}
.cardCategories{
  height: 230px; 
}
.n-mtx-3 {
  margin: -16px;
  margin-bottom: 0;
}
.modal-box {
  position: fixed;
  width: calc(100% - 20px);
  max-width: 800px;
  z-index: 99;
  top: 30px;
  left: 50%;
  translate: -50%;
  overflow: visible !important;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: #000000a1;
  z-index: 98;
}
button.close-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  opacity: 0.5;
  transition: 0.3s;
}
button.close-btn:hover {
  opacity: 1;
}
.modal-box-body {
  padding: 16px 0;
}
.modal-box-header {
  padding-bottom: 16px;
  border-bottom: 1px solid #ddd;
}
.modal-box-footer {
  padding-top: 16px;
  border-top: 1px solid #ddd;
  display: flex;
  column-gap: 16px;
  justify-content: flex-end;
}
h5.modal-box-title {
  margin: 0;
}
/* header */
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  border-bottom: 1px solid #7070701a;
}
.nav-toggler {
  display: none;
  font-size: 26px;
  margin-left: 10px;
}
.header-top {
  background: #263454;
  color: #fff;
  padding: 8px 0;
  display: flex;
  font-size: 14px;
}
.header-top a {
  color: #fff;
}

.form-select,
.form-control,
.form-select__control {
  border-radius: 12px !important;
  height: 50px;
}
span.form-select__indicator-separator {
  display: none;
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.form-check-input:focus,
.form-select:focus,
.form-control:focus,
.form-select__control--is-focused {
  box-shadow: 0 0 0 0.25rem rgb(255 180 0 / 20%) !important;
}

.form-select:focus,
.form-control:focus,
.form-select__control--is-focused {
  border-color: var(--primary-color) !important;
}
.form-select__menu {
  border-radius: 12px !important;
  padding: 4px 8px;
}
.form-select__option {
  border-radius: 8px;
}
.text-primary {
  color: var(--primary-color) !important;
}
.main-header {
  padding: 8px 0;
  background-color: #ffffffd4;
  backdrop-filter: blur(4px);
}
.navbar a {
  padding: 16px;
  color: #000;
  font-weight: 500;
}
.navbar a:hover,
.navbar a.active {
  color: var(--primary-color);
}
.navbar {
  display: flex;
  flex-wrap: wrap;
}
.logo {
  max-width: 100px;
}
/* /header */
/* banner */
section.banner {
  position: relative;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  padding-bottom: 50px;
}
.banner-content .title,
.banner-content .sub-title {
  text-shadow: 0 0 10px #00000070;
}

.banner .title {
  color: #fff;
}
section.banner:before {
  content: "";
  inset: 0;
  position: absolute;
  background-color: #00000031;
  z-index: -1;
}

.banner-slider {
  position: absolute !important;
  inset: 0 !important;
  z-index: -2 !important;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-delay: 5s;
}
.swiper-fade .swiper-slide-active img {
  animation: zoomEffect 12s linear alternate;
}
@keyframes zoomEffect {
  from {
    scale: 1;
  }
  to {
    scale: 1.2;
  }
}
.banner-form-top {
  padding: 20px 20px 0;
  background: #fff;
  max-width: max-content;
  border-radius: 20px 20px 0 0;
  position: relative;
}

.banner-form {
  padding: 20px;
  background: #fff;
  gap: 16px;
  border-radius: 0 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
}

.banner-form-top:before {
  content: "";
  position: absolute;
  left: 100%;
  height: 30px;
  width: 30px;
  background: transparent;
  border-radius: 50%;
  bottom: 0;
  box-shadow: -15px 15px #fff;
}
.banner-form > * {
  flex: 1;
}
.banner-portfolio span {
  margin-right: 16px;
  padding: 8px 16px;
  background-color: #ffffffd4;
  backdrop-filter: blur(4px);
  border-radius: 12px;
}
.banner-portfolio {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
}
.banner-portfolio i {
  font-size: 18px;
  margin-right: 5px;
}
/* /banner */
/* categories */
section.categories .card > div > i {
  height: 70px;
  min-width: 70px;
  background: #f5f5f5;
  display: grid;
  place-content: center;
  font-size: 30px;
  color: var(--primary-color);
  border: 1px dashed var(--primary-color);
  border-radius: 16px;
  margin-bottom: 10px;
}
.view-all-btn {
  translate: 120%;
  transition: 0.3s;
  color: var(--primary-color);
}
.view-all-btn:hover {
  color: #222;
}
section.categories .card:hover .view-all-btn {
  translate: 0;
}
.tab-bar button {
  padding: 8px 16px;
  border-radius: 35px;
  border: 1px solid transparent;
}

.tab-bar button.active {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background-color: #fff;
}
.tab-bar button:hover {
  color: var(--primary-color);
}
/* /categories */
/* properties */
button.wish-list-btn {
  background: #fff;
  position: absolute;
  top: 8px;
  z-index: 1;
  right: 8px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  padding-top: 2px;
  color: var(--primary-color);
  box-shadow: 0 3px 10px #00000020;
}
button.wish-list-btn i {
  transition: 0.3s;
  display: block;
}
button.wish-list-btn:hover i {
  rotate: 360deg;
}
button.wish-list-btn.active {
  background-color: var(--primary-color);
  color: #fff;
}
.property-features span {
  padding: 5px 10px;
  border: 1px solid #ececec;
  border-radius: 20px;
  margin-right: 5px;
  font-size: 12px;
  text-wrap: nowrap;
}
.property-features {
  flex-wrap: wrap;
  row-gap: 5px;
}
.property-features span svg {
  /* color: var(--primary-color); */
  margin-right: 5px;
  font-size: 16px;
}
.price {
  font-size: 22px;
  font-weight: 600;
  color: var(--primary-color);
}

.price .sub-text {
  font-size: 16px;
  font-weight: 400;
}
.property-details h5 {
  font-size: 16px;
  margin-bottom: 0;
}
.property-details {
  padding-top: 24px;
}

.property-badge {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  background: #7065f0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 7px;
  border-bottom-left-radius: 0 !important;
  position: absolute;
  top: -13px;
  left: -23px;
}
.property-badge:before {
  content: "";
  height: 7px;
  width: 7px;
  background: #5245ed;
  position: absolute;
  left: 0px;
  bottom: -7px;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}
.property-img {
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}
/* /properties */
/* places */
section.places .theme-img {
  border-radius: 16px;
  z-index: 1;
  cursor: pointer;
}
.place-title-cover > * {
  text-shadow: 0 0 16px #00000070;
}
section.places .theme-img:hover img {
  scale: 1.2;
  transition: 10s;
}
.place-title-cover {
  position: absolute;
  inset: 0;
  background: #0000001a;
  color: #fff;
  padding: 16px;
}

section.places .theme-img img {
  z-index: -1;
}

h3.place-title {
  font-size: 20px;
}

span.place-sub-title {
  font-size: 14px;
}
/* /places */
/* testimonial */
.testimonial-client img {
  height: 70px;
  width: 70px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #ececec;
}

.testimonial-client {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.swiper-button-prev,
.swiper-button-next {
  position: static !important;
  border: 1px solid #ddd;
  margin: 0 !important;
  float: left;
  padding: 8px 28px;
  height: auto !important;
  margin-right: 10px !important;
  border-radius: 30px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px !important;
  color: #222;
}
/* /testimonial */
/* footer */
footer {
  padding: 70px 0 0;
  background-color: #263454;
  color: #ffffffb8;
}
footer h4 {
  color: #fff;
}
.contact-box {
  display: flex;
  gap: 16px;
  align-items: center;
  background: #ffffff1a;
  padding: 16px;
  border-radius: 20px;
}
footer ul li a {
  color: #ffffffb8;
  padding: 5px 0;
  position: relative;
  display: block;
}
footer ul li a:hover {
  color: #fff;
}
footer ul li a i {
  position: absolute;
  left: -25px;
}
.footer-bottom a {
  color: #ffffffb8;
  margin-left: 10px;
}
.footer-bottom a:hover {
  color: var(--primary-color);
}
/* /footer */
/* contact page */
ul.contact-details a {
  padding: 10px 0;
  color: #222;
  display: flex;
  align-items: center;
  column-gap: 10px;
  transition: 0.3s;
  max-width: 400px;
}

ul.contact-details a i {
  height: 40px;
  min-width: 40px;
  background: #f5f5f5;
  display: grid;
  place-content: center;
  border-radius: 8px;
  border: 1px dashed;
}

ul.contact-details a:hover {
  color: var(--primary-color);
}
/* /contact page */
/* property-details */
.main-swiper .swiper-button-prev,
.main-swiper .swiper-button-next {
  position: absolute !important;
  padding: 8px 16px;
  border: none;
  background-color: #ffffff93;
  border-radius: 8px;
  margin: 0 !important;
  backdrop-filter: blur(2px);
}
.main-swiper .swiper-button-prev:after,
.main-swiper .swiper-button-next:after {
  color: #222;
}
.sub-swiper .swiper-wrapper {
  transform: none !important;
  flex-wrap: wrap;
  margin: 2px -2px 0 !important;
  width: calc(100% + 4px);
}
.sub-swiper .swiper-wrapper .swiper-slide {
  max-width: 20%;
  border: 2px solid #fff;
}
.sub-swiper .swiper-wrapper .swiper-slide .theme-img {
  filter: brightness(0.7);
}
.sub-swiper .swiper-wrapper .swiper-slide-thumb-active .theme-img {
  filter: brightness(1);
}
.property-features-v2 > span {
  font-size: 16px;
  padding: 5px 15px 5px 5px;
  display: flex;
  align-items: center;
  border-color: #ddd;
  margin-right: 10px;
}

.property-features-v2 > span svg {
  height: 30px;
  width: 30px;
  background: #ffb40059;
  border-radius: 50%;
  padding: 6px;
  border: 1px dashed #ffb400;
}

/* /property-details */
